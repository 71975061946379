<template>
<div class='clientLogs'>
	<div class='dateWrapper'>
		<DatePicker 
			nav-visibility='hidden'
			:is-inline='true' 
			:columns='2' 
			:rows='1' 
			:max-date='new Date()'
			:attributes='attributes'
			is-expanded
			v-model='d' 
			@update:from-page="debouncedFetchDays"
		/>
	</div>
	<div v-if="items && items.length" class="logs">
		<div v-for="(item, index) in items" :key="index" class="log">
			<div class="thumbnail"><LazyImage v-if='item.thumbnail' :src='item.thumbnail' :contain='true' /></div>
			<div class="content">
				<div class="heading">
					<div class="title"><span>{{formatDate(item.created)}}</span> &mdash; {{item.title}}</div>
					<div v-if="item.rpc!==undefined" class="rpc"><div>
						<template v-if="item.rpc===0">
							<div><img src="/img/faces/tooEasy.webp" /></div>
							<div class="attn">Too easy</div>
						</template>
						<template v-else-if="item.rpc===1">
							<div><img src="/img/faces/justRight.webp" /></div>
							<div>About right</div>
						</template>
						<template v-else-if="item.rpc===2">
							<div><img src="/img/faces/tooHard.webp" /></div>
							<div class="attn">Too hard</div>
						</template>
					</div></div>
					<div 
						class='openButton miniButton neutral' 
						@click='load(item.setId)'
					>{{ $t('elements.buttons.load') }}</div>
				</div>
				<div v-if="item.srw || item.timer" class="parameters">
					<div v-for="(srw, index) in item.srw" :key="`srw_${index}`" class="tag primary">{{srw.reps}} Reps<template v-if="srw.weight"> × {{srw.weight}}</template></div>
					<div v-for="(time, index) in item.timer" :key="`timer_${index}`" class="tag success">{{time.seconds}} Secs</div>
				</div>
				<div v-if="item.comments" class="comments">&quot;{{item.comments}}&quot;</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { clientsApi } from '@/services/api/modules/clinician/clients'
import LazyImage from '@/components/common/LazyImage'

export default {
	name: 'ClientLogs',
	components: { DatePicker, LazyImage },
	props: ['client'],
	data() { return {
		items: [],
		total: null,
		working: false,
		d: null,
		attributes: null,
		dates: []
	}},
	computed: {},
	methods: {
		async fetchDays(o) {
			const d = o ? new Date(o.year, o.month) : new Date()
			const date = dayjs(d).format('YYYY-MM')
			const [err, result] = await clientsApi.touch('fetchClientLogDays', { 
				id: this.client.id, 
				date, 
				tzOffset: new Date().getTimezoneOffset() 
			})
			if (!err) {
				const dates = result.days.map(day=>dayjs(day).toDate())
				this.dates = [...this.dates, ...dates]
					.map(date => date.getTime())
					.filter((date, i, array) => array.indexOf(date) === i)
					.map(time => new Date(time))
				this.attributes = [
					{
						highlight: {
							color: 'blue',
							fillMode: 'light',
						},
						dates: this.dates
					}
				]
			}
		},
		debouncedFetchDays: debounce(function(o) { this.fetchDays(o) }, 1000, { leading: false, trailing: true }),
		async fetchTop() {
			this.working = true
			const params = { id: this.client.id, tzOffset: new Date().getTimezoneOffset() }
			if (this.d) params.date = dayjs(this.d).format('YYYY-MM-DD')
			const [err, result] = await clientsApi.touch('fetchClientLogs', params)
			if (!err) {
				this.total = result.total 
				this.items = result.items 
			}
			this.working = false
		},
		async fetchNext() {
			if (this.total===this.items.length) return
			this.working = true
			const [err, result] = await clientsApi.touch('fetchClientLogs', { id: this.client.id, skip: this.items.length })
			if (!err) {
				this.items.push(...result.items)
			}
			this.working = false
		},
		formatDate(d) {
			return dayjs(d).format('MMM D YYYY')
		},
		async load(id) {
			this.working = true
			await this.$store.dispatch('setBuilder/fetch', id)
			this.$router.replace({ name: 'designer' })
			this.working = false
		}
	},
	watch: {
		async client(newClient, oldClient) {
			if (newClient.key && newClient.key !== oldClient.key) {
				this.items = []
				this.total = null
				this.d = null
				this.dates = []
				await Promise.all([this.fetchDays(), this.fetchTop()])
			}
		},
		d() {
			this.fetchTop()
		},
		working(v) {
			this.$emit('working', v)
		}
	},
	async mounted() {
		if (this.client) {
			await Promise.all([this.fetchDays(), this.fetchTop()])
		}
	}
}
</script>

<style lang='scss'>
.clientsManager .clientLogs {

	position: relative;

	.dateWrapper {
		padding: $size-gutter * 2;
		padding-top: $size-gutter * 4;

		> div { 
			background: none;
			border: none;
			border-radius: 0;
		}

		.vc-title, .vc-grid-cell { font-family: 'Cabin', sans-serif; }
		.vc-title { font-size: $size-font-standard; }
		.vc-weekday { font-size: $size-font-standard; }
		.vc-day-content { font-size: $size-font-standard; margin: 0; }
		.vc-grid-container.grid { padding: 0; }
		.vc-header { padding: $size-gutter * 2 0; }
		.vc-weeks { grid-gap: 1px !important; }
		.vc-svg-icon { top: -1px; position: relative; }
	}

	.logs {
		margin: 0 $size-gutter * 2;
		padding: $size-gutter * 2;
		background: #fff;
		border: 1px solid $color-neutral-shadow;
	}

	.log {
		display: flex;
		gap: $size-gutter * 2;
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		&:not(:last-child) { 
			margin-bottom: $size-gutter * 2; 
			border-bottom: 1px dotted $color-neutral-shadow;
			padding-bottom: $size-gutter * 2;
		}

		.content { 
			flex: 1; 
			.heading { display: flex; gap: $size-gutter * 2; align-items: center; }
			.title { flex: 2; span { color: $color-primary-accent; }}
			.rpc { 
				flex: 1;
				display: flex;
				justify-content: center;
				> div {
					flex: 0;
					white-space: nowrap;
					display: flex;
					align-items: center;
					gap: $size-gutter;
					> div:first-child {
						width: 24px;
						height: 24px;
						min-width: 24px;
						> img { width: 100%; height: 100%; object-fit: contain; display: block;}
					}
					> div:last-child {
						flex: 1;
					}
				}
				.attn { color: $color-alert; }
			}
			.parameters {
				padding-top: $size-gutter * 2;
				display: grid;
				grid-gap: 1px;
				grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
				> div { background: #e4f4ff; border: none; }
			}
			.comments {
				padding: $size-gutter * 2;
				background: $button-gradient-primary;
				font-size: $size-font-large;
				line-height: $size-lineHeight-large;
				text-align: center;
				font-style: italic;
				margin-top: $size-gutter * 2;
				color: #fff;
				max-width: 400px;
				margin-left: auto;
				margin-right: auto;
				border-radius: 12px;
			}
			.openButton { border: 1px solid $color-neutral-shadow; }
		}

		.thumbnail {
			position: relative;
			width: 50px;
			height: 50px;
			min-width: 50px;
			&:empty { height: 0; }
		}
	}
}
</style>
